<template>
    <section id="pricing-plan">
    

        <!-- title text and switch button -->
        <div class="text-center">
    
            <h1 class="mt-1">
                付款細節
            </h1>
        </div>
        <!--/ title text and switch button -->
    
        <!-- pricing plan cards -->
        <b-row class="pricing-card">
            <b-col sm="12" md="12" lg="12" class="mx-auto">
                <b-overlay :show="processing" :opacity="'0.95'">
                <b-row>
                    <b-col>
                        <b-card>
                             <h4>
         
                            付款編號 : # {{ order.uuid }}<br>
                            付款金額 : HKD ${{ order.amount }}<br>
                            付款方式 : {{ order.payment_type }}<br>
                            結果 : {{ order.status }}<br>
                            </h4>
                            <hr>
                            如有任何付款問題, 歡迎立即聯絡我們～

                        </b-card>
                        
                    </b-col>
                </b-row>
            </b-overlay>
            </b-col>
        </b-row>
        <!--/ pricing plan cards -->
        <!-- pricing free trial -->
        <div class="pricing-free-trial">
            <b-row>
                <b-col lg="10" offset-lg="3" class="mx-auto">
                    <div class="pricing-trial-content d-flex justify-content-between">
                        <div class="text-center text-md-left mt-3">
                            <h3 class="text-primary">
                                還有疑慮 或 問題？
                            </h3>
                            <h5>可以即時聯絡我們，了解更多</h5>
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-2 mt-lg-3" href="https://wa.me/85244464116" target="_blank">
                                <font-awesome-icon :icon="['fab', 'whatsapp']" /> &nbsp;&nbsp;按此 whatsapp 聯絡我們
                            </b-button>
                        </div>
    
                        <!-- images -->
                        <b-img fluid :src="require('@/assets/images/illustration/pricing-Illustration.svg')" class="pricing-trial-img" alt="svg img" />
                        <!--/ images -->
                    </div>
                </b-col>
            </b-row>
        </div>
        <!--/ pricing free trial -->
    
    </section>
    </template>
    
    <script>
    import {
        BFormCheckbox,
        BOverlay,
        BRow,
        BCol,
        BCard,
        BModal,
        BImg,
        BCardText,
        BListGroup,
        BListGroupItem,
        BButton,
        BBadge,
        BInputGroup,
        BFormInput,
        BForm,
        BFormGroup,
        BInputGroupAppend,
        BInputGroupPrepend,
        BCollapse,
        VBToggle
    } from 'bootstrap-vue'
    import router from '@/router'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Ripple from 'vue-ripple-directive'
    /* eslint-disable global-require */
    export default {
        components: {
            BFormCheckbox,
            router,
            BOverlay,
            BButton,
            BCardText,
            VBToggle,
            BListGroup,
            ToastificationContent,
            BListGroupItem,
            BCollapse,
            BFormGroup,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroupPrepend,
            BForm,
            BRow,
            BCol,
            BModal,
            BCard,
            BBadge,
            BImg,
            AppCollapseItem,
            AppCollapse,
        },
        directives: {
            Ripple,
            'b-toggle': VBToggle,
        },
        data() {
            return {
                order_id : router.currentRoute.params.order_id,
                success : false,
                processing: true,
                order: {
                    order_id : '',
                    amount : "",
                    result : "",
                    txamt : 0,
                    payment_type: ""
                },
                timer: null
            }
        },
        created() {
            this.check()
            this.test()
        },
        methods: {
            test() {
                this.timer = setInterval(() => {
                    this.check()
                }, 3000)
            },
            check() {
                this.$http.get(process.env.VUE_APP_API_BASE+'/payment_gateway/check/' + this.order_id + '/' )
                    .then(res => {
                        if (res.data.order.status == 'SUCCESS') { 
                            this.order.payment_type = "ONLINE PAYMENT"
                            this.processing = false
                            clearInterval(this.timer)
                            switch (res.data.order.payment_type) {
                                case "stripe":
                                    this.order = res.data.stripe
                                    break;
                                case "qfpay":
                                    this.order = res.data.qfpay
                                    console.log(this.order)
                                    break;
                            }
                        }
                    })
                },
        },
    }
    /* eslint-disable global-require */
    </script>
    
    <style lang="scss">
    @import '@core/scss/vue/pages/page-pricing.scss';
    </style>
    